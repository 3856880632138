// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询验单白名单列表
export const queryWhiteList = (data: any) =>
  request('/payAdminServer/validate/order/whitelist/list', null, 'POST_JSON', data)

// 添加验单白名单
export const addWhiteList = (data: any) =>
  request('/payAdminServer/validate/order/whitelist/add', null, 'POST_JSON', data)

// 修改验单白名单
export const updateWhiteList = (data: any) =>
  request('/payAdminServer/validate/order/whitelist/update', null, 'POST_JSON', data)

// 删除验单白名单
export const deleteWhiteList = (data: any) =>
  request('/payAdminServer/validate/order/whitelist/delete', null, 'POST_JSON', data)

// 批量删除验单白名单
export const batchDeleteWhiteList = (data: any) =>
  request('/payAdminServer/validate/order/whitelist/batchDelete', null, 'POST_JSON', data)

// 获取产品列表
export const queryWhiteAppList = () =>
  request('/payAdminServer/validate/order/whitelist/getAppList', null, 'POST_JSON', null)

// 获取产品列表
export const queryAppListByAccountId = (data: { accountId: number }) =>
  request('/payAdminServer/validate/order/whitelist/getAppListByAccountId', null, 'POST_JSON', data)

// 获取产品列表
export const getAppListByPayChnl = (data: { payChnl: string }) =>
  request('/payAdminServer/validate/order/whitelist/getAppListByPayChnl', null, 'POST_JSON', data)

// 账号管理-账号维度列表查询接口
export const queryEntityAccount = () =>
  request('/payAdminServer/account/entity', null, 'POST_JSON', null)

// 查询搜索框的支付渠道下拉列表
export const queryFilterPayChnl = () =>
  request('/payAdminServer/validate/order/whitelist/payChnl', null, 'POST_JSON', null)

// 查询全部支付渠道下拉列表
export const queryModalPayChnl = () =>
  request('/payAdminServer/validate/order/whitelist/dimension/payChnl', null, 'POST_JSON', null)
